import { PoolTypeFilter } from '@/types/pools';
import { PoolType } from '@xclabs/balancer-sdk';

export const burrifiedPoolType = (poolType: PoolType) => {
  switch (poolType) {
    case PoolType.FX:
      return 'Burr';
    case PoolType.ComposableStable:
      return 'Multi Stable';
    case PoolType.Weighted:
      return 'Generalized';
    default:
      return poolType as string;
  }
};

export const burrifiedPoolTypeFilter = (filter: PoolTypeFilter) => {
  switch (filter) {
    case PoolTypeFilter.FX:
      return 'Burr';
    case PoolTypeFilter.Stable:
      return 'Multi Stable';
    case PoolTypeFilter.Weighted:
      return 'Generalized';
    default:
      return filter as string;
  }
};
